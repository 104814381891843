export default class MessageTableItem {
    mitLevelId = 0;
    mitLevelStatus = 0;
    mitLevelName = "";
    mitLevelColor = "";
    mitLevelType = "";
    mitMessageType = "";
    mitMessageTypeName = "";
    mitLevelDingding = 0;
    mitLevelDingdingName = "";
    mitLevelLargeScreen = 0;
    mitLevelLargeScreenName = "";
    mitLevelRemark = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
