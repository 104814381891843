import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import LevelTableItem from "@/entity/module/LevelTableItem";
import API from "@/api/module/level";

@Options({
    name: "app-module-level",
})
export default class Level extends BaseTableMixins<LevelTableItem> implements Partial<BaseTableInterface<LevelTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.editTableItem = new LevelTableItem();
        this.editModalItem = new LevelTableItem();
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new LevelTableItem();
        this.editModalItem = new LevelTableItem();
        this.editModalItem.mitLevelStatus = 1;
        this.editModalItem.mitLevelDingding = 1;
        this.editModalTitle = "添加消息等级";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.mitLevelName)) return "消息等级不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.mitLevelType)) return "等级类型不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.mitMessageType)) return "消息类型不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.mitLevelColor)) return "消息颜色不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.mitLevelDingding)) return "是否推送钉钉不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.mitLevelLargeScreen)) return "是否大屏展示不能为空！";
        return null;
    }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改消息标识 (" + this.editModalItem.mitLevelName + ")";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.mitLevelId)) return "消息等级ID不能为空！";
        return this.addSubmitChecking();
    }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除该消息标识",
            message: "消息等级: " + this.editModalItem.mitLevelName + "<br>请确认删除？",
        };
    }

    //排序弹窗配置
    public sortModalSetting(): void {
        this.editSortBaseTitle = "消息等级排序";
    }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "消息等级状态",
            message: this.editModalItem.mitLevelName + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.mitLevelStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.mitLevelStatus = this.editModalItem.mitLevelStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.mitLevelId)) return "批次ID不能为空！";
        return null;
    }

    //消息等级变更事件
    public mitLevelNameChange(value: string) {
        const foundColorItem: any = this.colors.find((item: any) => item.value === value);
        if (foundColorItem) this.editModalItem.mitLevelColor = foundColorItem.color;
    }

    //通过级别获取颜色名称
    public toColorName(value: string): string {
        const foundColorItem: any = this.colors.find((item: any) => item.value === value);
        if (foundColorItem) {
            return foundColorItem.name;
        } else {
            return "未知";
        }
    }

    public messageType: any = [
        { label: "告警", value: "warning" },
        { label: "故障", value: "failure" },
    ];

    public colors: any = [
        {
            name: "红色",
            color: "#FF0000",
            value: "L5",
            label: "L5",
        },
        {
            name: "橙色",
            color: "#FF8000",
            value: "L4",
            label: "L4",
        },
        {
            name: "黄色",
            color: "#FFFF00",
            value: "L3",
            label: "L3",
        },
        {
            name: "蓝色",
            color: "#0000FF",
            value: "L2",
            label: "L2",
        },
        {
            name: "灰色",
            color: "#C0C0C0",
            value: "L1",
            label: "L1",
        },
    ];
}
